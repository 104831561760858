@import "bootstrap-4.1.3/bootstrap";

form#dhl-label,
form#dhl-label-it {
    .pdf-label-create {
        opacity: 1;

        transition: opacity 250ms linear .25ms;
        -moz-transition: opacity 250ms linear .25ms;
        -webkit-transition: opacity 250ms linear .25ms;
        -o-transition: opacity 250ms linear .25ms;

        &.hide {
            opacity: 0;
            display: none;
        }
    }

    .pdf-label-download {
        opacity: 0;
        -moz-opacity: 0;
        visibility: hidden;
        display: none;

        &.show {
            display: block;
            visibility: visible;
            opacity: 1;
            -moz-opacity: 1;
            animation: fade-in 350ms, btn-download 300ms 550ms 4 alternate;
            -webkit-animation: fade-in 350ms, btn-download 300ms 550ms 4 alternate;
            -moz-animation: fade-in 350ms, btn-download 300ms 550ms 4 alternate;
        }
    }
}

.form-message {
    display: none;
    opacity: 0;
    -moz-opacity: 0;
    visibility: hidden;

    //transition: opacity 250ms linear .200ms;
    //-moz-transition: opacity 250ms linear .200ms;
    //-webkit-transition: opacity 250ms linear .200ms;
    //-o-transition: opacity 250ms linear .200ms;

    &.show {
        display: block;
        opacity: 1;
        -moz-opacity: 1;
        visibility: visible;

        animation: fade-in-message 350ms linear;
        -moz-animation: fade-in-message 350ms linear;
        -webkit-animation: fade-in-message 350ms linear;
    }
}

@-webkit-keyframes btn-download {
    to {
        -webkit-transform: scale(1.05);
        -webkit-box-shadow: 0 0 20px 0 rgba(204,0,0,1);
    }
}
@-moz-keyframes btn-download {
    to {
        -moz-transform: scale(1.05);
        -moz-box-shadow: 0 0 20px 0 rgba(204,0,0,1);
    }
}
@keyframes btn-download {
    to {
        transform: scale(1.05);
        box-shadow: 0 0 20px 0 rgba(204,0,0,1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        -moz-opacity: 0;
        transform: translateX(50%);
    }

    100% {
        opacity: 1;
        -moz-opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in-message {
    0% {
        opacity: 0;
        -moz-opacity: 0;
    }

    100% {
        opacity: 1;
        -moz-opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}